import { useSelector } from 'react-redux';

import useAppEnvironment from './useAppEnvironment';
import { selectUserLocale } from '../selectors/user';
import { defaultLocale } from '../modules/user';

export const tutorialBaseUrl = 'https://docs.stickerstars.de';

export const tutorialPermalinks = {
  wedding: {
    faq: {
      url: 'https://wedding.stickerstars.de/faq',
      languages: ['de', 'en', 'fr'],
    },
    budget: {
      url: 'https://wedding.stickerstars.de/preise',
      languages: ['de', 'en', 'fr'],
    },
  },
  business: {
    faq: {
      url: 'https://business.stickerstars.de/informationen/faq',
      languages: ['de'],
    },
    budget: {
      url: 'https://business.stickerstars.de/angebot-erstellen',
      languages: ['de'],
    },
  },
  default: {
    start: { url: '', languages: ['de', 'en'] },
    stickerlink: {
      url: 'creating-stickers/collecting-stickers',
      languages: ['de', 'en'],
    },
    stock: {
      url: 'designing-the-album/using-the-image-library',
      languages: ['de', 'en'],
    },
    layouts: {
      url: 'designing-the-album/using-page-layouts',
      languages: ['de', 'en'],
    },
    sections: {
      url: 'creating-stickers/managing-chapters',
      languages: ['de', 'en'],
    },
    viewport: { url: 'basics/operations', languages: ['de', 'en'] },
    stickerPlacement: {
      url: 'creating-stickers/placing-stickers',
      languages: ['de', 'en'],
    },
    checkout: {
      url: 'order-and-delivery/order-process',
      languages: ['de', 'en'],
    },
    images: {
      url: 'designing-the-album/placing-background-images',
      languages: ['de', 'en'],
    },
    faq: {
      url: 'https://wedding.stickerstars.de/faq',
      languages: ['de', 'en', 'fr'],
    },
    budget: {
      url: 'https://wedding.stickerstars.de/preise',
      languages: ['de', 'en', 'fr'],
    },
  },
};

const buildUrl = (resource, locale) => {
  const { url, languages } = resource;
  let localePath = `/${defaultLocale}`;

  if (locale && languages.includes(locale)) {
    localePath = locale === 'de' ? '' : `/${locale}`;
  }

  if (url.startsWith('http')) {
    const urlObj = new URL(url);
    urlObj.pathname = localePath + urlObj.pathname;
    return urlObj.toString();
  }

  return `${tutorialBaseUrl}${localePath}/${url}`;
};

function useTutorial() {
  const { environment } = useAppEnvironment();
  const locale = useSelector(selectUserLocale);

  const linkTo = resource =>
    buildUrl(
      tutorialPermalinks[environment]?.[resource] ||
        tutorialPermalinks.default[resource],
      locale
    );

  return {
    linkTo,
  };
}

export default useTutorial;
