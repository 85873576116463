import React from 'react';
import { string, shape, object } from 'prop-types';
import { useDrag } from 'react-dnd';
import { batch, useDispatch, useSelector } from 'react-redux';

import ToolButton from './ToolButton';
import AdminOnly from '../../../generic/AdminOnly';
import { itemTypes } from '../../../../constants';
import { generateId } from '../../../../util';
import { insertElements } from '../../../../actions/workspace';
import { elementSelect } from '../../../../modules/selection';
import { historyAnchor } from '../../../../modules/history';
import { getTargetSpreadId } from '../../../../selectors/legacy';
import useLocale from '../../../../hooks/localization/useLocale';

function ToolCreateButton({ tool }) {
  const { t } = useLocale();
  const { id, title, template, adminOnly } = tool;
  const targetSpreadId = useSelector(getTargetSpreadId);

  const dispatch = useDispatch();

  const handleCreate = () => {
    const { props, type, x, y } = template;

    const nodeId = generateId();
    const newNode = {
      type,
      props: {
        ...props,
        id: nodeId,
      },
      x,
      y,
    };

    batch(() => {
      dispatch(insertElements([newNode], targetSpreadId));
      dispatch(elementSelect([nodeId]));
      dispatch(historyAnchor());
    });
  };

  const [, drag] = useDrag({
    item: { data: template, type: itemTypes.element },
  });

  const button = (
    <ToolButton
      placement="left"
      onClick={handleCreate}
      title={t(title)}
      dragRef={drag}
      id={id}
    />
  );

  if (adminOnly) {
    return <AdminOnly>{button}</AdminOnly>;
  }

  return button;
}

ToolCreateButton.propTypes = {
  tool: shape({
    id: string.isRequired,
    title: string.isRequired,
    template: shape({
      props: object, // eslint-disable-line react/forbid-prop-types
      type: string,
    }),
  }).isRequired,
};

export default ToolCreateButton;
