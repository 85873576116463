import React, { useState } from 'react';
import { object } from 'prop-types';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';

import AppLayout from '../../components/ui/AppLayout';
import CheckoutForm from './CheckoutForm';
import { ReactRouterMatchShape } from '../../components/shapes';
import Icon from '../../components/Icon';
import useAuth from '../../hooks/useAuth';
import FullScreenSpinner from '../app/FullScreenSpinner';
import useQuery from '../../hooks/useQuery';
import useAnalytics from '../app/useAnalytics';
import useFirstTime from '../../hooks/useFirstTime';
import useLocale from '../../hooks/localization/useLocale';

function Checkout({
  match: {
    params: { albumId },
  },
  history,
}) {
  const {
    user: { email },
  } = useAuth();
  const analytics = useAnalytics();
  const { t } = useLocale();

  const initialState = {
    organization: '',
    firstName: '',
    lastName: '',
    email,
    phone: '',
    address1: '',
    address2: '',
    zipCode: '',
    city: '',
    countryCode: 'DE',
    noAlbums: '50',
    comment: '',
    orderFeatures: [],
    voucherCode: '',
    quoteName: '',
  };

  const [formData, setFormData] = useState(initialState);
  const { noAlbums, countryCode, orderFeatures, voucherCode } = formData;
  const [checkoutAlbumCount, setCheckoutAlbumCount] = useState(noAlbums);

  const orderFeatureParams = orderFeatures
    .map(el => `&order_features[]=${el}`)
    .join('');

  const {
    isLoading: isFetchingCheckout,
    data,
    error,
  } = useQuery(
    `/albums/${albumId}/checkout?no_albums=${noAlbums}&country_code=${countryCode}${orderFeatureParams}&voucher_code=${voucherCode}`,
    [checkoutAlbumCount, countryCode, orderFeatureParams, voucherCode]
  );

  const { checkout } = data || {};
  const nextReleaseDate = checkout?.album?.release_date;

  useFirstTime(
    () =>
      setFormData(lastFormData => ({
        ...lastFormData,
        releaseDate: nextReleaseDate,
      })),
    !!nextReleaseDate
  );

  const handleAlbumCountChange = () => {
    setCheckoutAlbumCount(noAlbums);
    analytics.track('Album Slider Updated', { noAlbums });
  };

  return (
    <AppLayout>
      <div className="checkout">
        <Link to={`/album/${albumId}`}>
          <Icon name="chevron_left" className="d-inline-block mr-1" />
          {t('back')}
        </Link>
        <h1 className="font-weight-bold mt-3">{t('checkout.title')}</h1>
        {error && (
          <Alert variant="warning">
            {t('checkout.errorMessage')}{' '}
            <Button variant="link" onClick={() => history.goBack()}>
              {t('back')}
            </Button>
          </Alert>
        )}
        {checkout ? (
          <>
            <div className="px-0 col-lg-12 mx-auto py-4">
              {t('checkout.personalInfoMessage')}
            </div>
            <CheckoutForm
              checkout={checkout}
              formData={formData}
              setFormData={setFormData}
              onCheckoutAlbumCountChange={handleAlbumCountChange}
              isFetchingCheckout={isFetchingCheckout}
            />
          </>
        ) : (
          <FullScreenSpinner />
        )}
      </div>
    </AppLayout>
  );
}

Checkout.propTypes = {
  match: ReactRouterMatchShape.isRequired,
  history: object.isRequired,
};

export default Checkout;
