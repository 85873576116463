import React, { useEffect, useMemo, useRef, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import {
  useEffectOnce,
  useClickAway,
  useSetState,
  useDebounce,
} from 'react-use';
import { arrayOf, array, func, bool, number } from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { Splide, SplideSlide } from '@splidejs/react-splide';

import useFadeIn from '../../../../hooks/useFadeIn';
import { StickerShape } from '../../../shapes';
import useImageUpload from '../../../../hooks/useImageUpload';
import {
  deleteSticker,
  unlinkStickerCellForStickerId,
  uploadStickerImage,
} from '../../../../actions/stickers';
import StickerPreview from './StickerPreview';
import FileInputButton from '../../../generic/FileInputButton';
import useScreenSize from '../../../../hooks/useScreenSize';
import { useConfirmation } from '../../ConfirmModal/ConfirmationService';
import Icon from '../../../Icon';
import { historyAnchor } from '../../../../modules/history';
import StickerTemplateView from '../../AlbumWizard/Sidebar/StylesPanel/StickerTemplateView';
import { selectStickerTemplateSettings } from '../../../../selectors/albums';
import { defaultStickerTemplateId } from '../../../../constants';
import stickerTemplates from '../../../../assets/sticker';
import { updateControls } from '../../../../modules/controls';
import CollapsibleCard from './CollapsibleCard';
import { getImage, getImages } from '../../../../selectors/images';
import useNotifications from '../../../../hooks/useNotifications';
import useLocale from '../../../../hooks/localization/useLocale';

const templateViewSplideOptions = {
  rewind: true,
  arrows: false,
  pagination: false,
  autoWidth: true,
};

export const defaultSticker = {
  name: '',
  position: '',
  doubleSticker: false,
  cx: 0,
  cy: 0,
  cscale: 1,
  crotation: 0,
};

function StickerForm({
  onChange,
  onSubmit,
  onClose,
  autoSubmit,
  viewportScale,
  sectionOptions,
  initialSticker,
  isExistingSticker,
  deleteButton,
}) {
  const ref = useRef();
  const nameInputRef = useRef();
  const viewportRef = useRef();
  const { isMobile } = useScreenSize();
  const confirm = useConfirmation();
  const { createError } = useNotifications();
  const { t } = useLocale();

  const dispatch = useDispatch();

  const [operationActive, setOperationActive] = useState(false);
  const [sticker, setSticker] = useSetState({
    ...defaultSticker,
    ...initialSticker,
  });

  const images = useSelector(getImages);
  const imageObject = getImage({ images }, sticker.image);
  const hasImage = !isEmpty(imageObject);

  const shouldAutosave = isExistingSticker && hasImage;

  /** Auto-save sticker updates for existing stickers */
  useDebounce(
    () => {
      if (shouldAutosave) {
        onSubmit(sticker);
      }
    },
    200,
    [sticker]
  );

  /**
   * We're "listening" to updates on sticker attributes
   * that might be happening _outside the StickerForm_, such
   * as updated image (due to upload completed) and merge them
   * into our local sticker copy.
   */
  const nextImage = initialSticker?.image;
  useEffect(() => {
    setSticker({
      image: nextImage,
    });
  }, [setSticker, nextImage]);

  useEffectOnce(() => {
    // we don't want to open the keyboard on mount on mobile devices
    if (isMobile) {
      return;
    }

    nameInputRef.current.focus();
    nameInputRef.current.setSelectionRange(
      0,
      nameInputRef.current.value.length
    );
    nameInputRef.current.select();
  });

  const { createStickerImage } = useImageUpload();

  const { style } = useFadeIn(ref);

  const templateId =
    useSelector(selectStickerTemplateSettings)?.id || defaultStickerTemplateId;

  const singleOrDouble = sticker.doubleSticker ? 'double' : 'single';

  const stickerTemplate = useMemo(
    () => stickerTemplates[templateId][singleOrDouble],
    [templateId, singleOrDouble]
  );

  const selectedStickerLayout = stickerTemplate.sticker[sticker.stickerLayout]
    ? sticker.stickerLayout
    : 'default';

  const selectedStickerCellLayout = stickerTemplate.cell[sticker.cellLayout]
    ? sticker.cellLayout
    : 'default';

  function handleSubmitAndClose(event) {
    event.preventDefault();
    onSubmit(sticker);
    onClose();
  }

  const handleClickAway = autoSubmit ? handleSubmitAndClose : onClose;
  const ignoreClickAway = () => {};
  useClickAway(ref, operationActive ? ignoreClickAway : handleClickAway, [
    'click',
  ]);

  const updateSticker = delta => {
    setSticker(delta);
    const nextSticker = { ...sticker, ...delta };
    onChange(nextSticker);
  };

  const makeHandleTextChange = key => event =>
    updateSticker({ [key]: event.target.value });

  const handleDoublestickerChange = () => {
    updateSticker({
      doubleSticker: !sticker.doubleSticker,
      // reset sticker design and cell design -- some keys might not exist
      stickerLayout: 'default',
      cellLayout: 'default',
    });
  };

  const handleSectionSelect = event => {
    updateSticker({ sectionId: event.target.value });
  };

  const handleDeleteSticker = () => {
    confirm({
      body: t('editor.stickerForm.deleteWarning'),
    })
      .then(() => {
        onClose();

        batch(() => {
          dispatch(unlinkStickerCellForStickerId(sticker.id));
          dispatch(deleteSticker(sticker.id));
          dispatch(historyAnchor());
        });
      })
      .catch(() => {});
  };

  const handleImageUpload = event => {
    const [file] = Array.from(event.target.files);
    dispatch(
      uploadStickerImage({
        id: sticker.id,
        createStickerImage,
        file,
      })
    );
  };

  async function handleImageDownload() {
    const { full: url, filename } = imageObject.blob;

    try {
      const image = await fetch(url);
      const imageBlob = await image.blob();
      const imageURL = URL.createObjectURL(imageBlob);

      const a = document.createElement('a');
      a.href = imageURL;
      a.download = filename;

      ref.current.appendChild(a);
      a.click();
      ref.current.removeChild(a);
    } catch (err) {
      createError(err.toString());
    }
  }

  const handleStickerLayoutChange = stickerLayout => {
    updateSticker({ stickerLayout });
  };

  const handleCellLayoutChange = cellLayout => {
    updateSticker({ cellLayout });
  };

  return (
    <Form
      ref={ref}
      onSubmit={handleSubmitAndClose}
      className={`sticker-form ${hasImage ? 'has-image' : ''} qa-sticker-form`}
      style={style}
    >
      {hasImage && (
        <div className="border-bottom p-3">
          {t('editor.stickerForm.headline')}
        </div>
      )}
      <div className="p-3 qa-sticker-form-preview-wrapper">
        {isMobile && (
          <div className="d-flex justify-content-end">
            <Form.Group className="text-center my-2">
              <Button
                variant="link"
                className="sticker-form-close-button qa-sticker-form-close-button px-0"
                onClick={onClose}
              >
                <Icon name="closeThin" />
              </Button>
            </Form.Group>
          </div>
        )}
        {hasImage && (
          <StickerPreview
            viewportRef={viewportRef}
            sticker={sticker}
            imageObject={imageObject}
            viewportScale={viewportScale}
            setOperationActive={setOperationActive}
            updateSticker={updateSticker}
          />
        )}
        {isExistingSticker && (
          <ButtonGroup>
            <FileInputButton
              name="sticker-image-upload"
              variant="light"
              className="m-0 d-block qa-sticker-form-upload-image"
              accept="image/jpeg, image/png"
              onChange={handleImageUpload}
              size="sm"
              block={!hasImage}
            >
              {hasImage
                ? t('editor.stickerForm.changeImage')
                : t('editor.stickerForm.addImage')}
            </FileInputButton>
            {hasImage && (
              <Button
                name="sticker-image-download"
                variant="light"
                className="ml-1 qa-sticker-form-download-image"
                onClick={handleImageDownload}
                size="sm"
              >
                {t('editor.stickerForm.downloadImage')}
              </Button>
            )}
          </ButtonGroup>
        )}
      </div>
      <div className="px-3 pb-3">
        <Form.Group className="my-2">
          <Form.Control
            type="text"
            name="new_sticker_name"
            value={sticker.name}
            ref={nameInputRef}
            placeholder={t('editor.stickerForm.namePlaceholder')}
            onChange={makeHandleTextChange('name')}
            className={!sticker.name && 'border-danger'}
            maxLength={30}
            size="sm"
          />
        </Form.Group>
        <Form.Group className="my-2">
          <Form.Control
            type="text"
            name="new_sticker_position"
            value={sticker.position}
            placeholder={t('editor.stickerForm.positionPlaceholder')}
            onChange={makeHandleTextChange('position')}
            maxLength={30}
            size="sm"
          />
        </Form.Group>
        {sectionOptions?.length > 0 && (
          <Form.Control
            as="select"
            name="new_sticker_section"
            custom
            value={sticker.sectionId}
            onChange={handleSectionSelect}
            className={sticker.sectionId ? null : 'border-danger'}
            size="sm"
          >
            <option key="" value="">
              {t('selectOption')}
            </option>
            {sectionOptions.map(([id, name]) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
          </Form.Control>
        )}
        <Form.Group className="mt-2 mb-0">
          <Form.Check
            className="qa-check-doublesticker"
            type="switch"
            label={t('editor.stickerForm.doubleSticker')}
            id="check-doublesticker"
            checked={sticker.doubleSticker}
            onChange={handleDoublestickerChange}
          />
        </Form.Group>
      </div>
      {hasImage && isExistingSticker && (
        <>
          <CollapsibleCard
            title={t('editor.stickerForm.stickerLayout')}
            initialOpen={false}
            className="qa-sticker-layouts-card"
          >
            <Form.Group>
              <div>
                <Splide options={templateViewSplideOptions}>
                  {Object.keys(stickerTemplate.sticker).map(key => (
                    <SplideSlide key={key}>
                      <div
                        key={key}
                        className={`sticker-layout-item ${
                          selectedStickerLayout === key ? 'active' : ''
                        } qa-sticker-layout-item`}
                        onClick={() => handleStickerLayoutChange(key)}
                        onKeyPress={() => handleStickerLayoutChange(key)}
                        role="button"
                        tabIndex={0}
                      >
                        <StickerTemplateView
                          stickerTemplateId={templateId}
                          stickerLayout={key}
                          doubleSticker={sticker.doubleSticker}
                        />
                      </div>
                    </SplideSlide>
                  ))}
                </Splide>
              </div>
            </Form.Group>
          </CollapsibleCard>
          <CollapsibleCard
            title={t('editor.stickerForm.stickerCell')}
            initialOpen={false}
            className="qa-cell-layouts-card"
          >
            <Form.Group>
              <div>
                <Splide options={templateViewSplideOptions}>
                  {Object.keys(stickerTemplate.cell).map(key => (
                    <SplideSlide key={key}>
                      <div
                        className={`sticker-layout-item ${
                          selectedStickerCellLayout === key ? 'active' : ''
                        } qa-cell-layout-item`}
                        onMouseEnter={() =>
                          dispatch(updateControls({ showStickers: false }))
                        }
                        onMouseLeave={() =>
                          dispatch(updateControls({ showStickers: true }))
                        }
                        onClick={() => handleCellLayoutChange(key)}
                        onKeyPress={() => handleCellLayoutChange(key)}
                        role="button"
                        tabIndex={0}
                      >
                        <StickerTemplateView
                          stickerTemplateId={templateId}
                          stickerLayout={key}
                          doubleSticker={sticker.doubleSticker}
                          cell
                        />
                      </div>
                    </SplideSlide>
                  ))}
                </Splide>
              </div>
            </Form.Group>
          </CollapsibleCard>
        </>
      )}
      {autoSubmit || shouldAutosave ? (
        <input type="submit" hidden />
      ) : (
        <Form.Group className="text-center my-2">
          <Button
            variant="primary"
            type="submit"
            block
            className="qa-sticker-form-submit"
          >
            {t('save')}
          </Button>
        </Form.Group>
      )}
      {deleteButton && isExistingSticker && (
        <Form.Group className="text-center my-2">
          <Button
            variant="outline-danger"
            block
            className="qa-sticker-form-delete-sticker"
            onClick={handleDeleteSticker}
          >
            {t('delete')}
          </Button>
        </Form.Group>
      )}
    </Form>
  );
}

StickerForm.defaultProps = {
  initialSticker: null,
  autoSubmit: false,
  viewportScale: 3,
  onChange: () => {},
  sectionOptions: undefined,
  isExistingSticker: false,
  deleteButton: false,
};

StickerForm.propTypes = {
  onChange: func,
  onSubmit: func.isRequired,
  onClose: func.isRequired,
  initialSticker: StickerShape,
  autoSubmit: bool,
  viewportScale: number,
  sectionOptions: arrayOf(array), // eslint-disable-line react/forbid-prop-types
  isExistingSticker: bool,
  deleteButton: bool,
};

export default StickerForm;
