import React, { useState } from 'react';
import { arrayOf, string, func, bool } from 'prop-types';
import Form from 'react-bootstrap/Form';

import Logo from '../../../../../assets/icons/logo.svg';
import { BlueprintPreviewShape, WorkspaceShape } from '../../../../shapes';
import useScreenSize from '../../../../../hooks/useScreenSize';
import FullScreenSpinner from '../../../../../containers/app/FullScreenSpinner';
import AlbumPreviewNavigator from '../../Content/AlbumPreviewNavigator';
import useLocale from '../../../../../hooks/localization/useLocale';

function BlueprintImage({ url, hidden }) {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <img
      className="shadow-lg"
      src={url}
      style={{
        height: '180px',
        transition: 'opacity 0.4s ease',
        opacity: isLoading ? 0 : 1,
        display: hidden ? 'none' : 'inline',
      }}
      alt="Blueprint cover preview"
      onLoad={() => setIsLoading(false)}
    />
  );
}

BlueprintImage.propTypes = {
  url: string.isRequired,
  hidden: bool.isRequired,
};

function BlueprintsPanel({
  blueprints,
  currentBlueprintId,
  onBlueprintChange,
  categories,
  categoryFilter,
  onCategoryChange,
  isLoading,
  workspace,
}) {
  const { isMobile } = useScreenSize();
  const { t } = useLocale();

  return (
    <div
      className={`qa-blueprints-panel d-flex flex-column ${
        !isMobile ? 'scrollable' : ''
      }`}
    >
      {isLoading ? (
        <FullScreenSpinner />
      ) : (
        <>
          <div className="text-center">
            <a href="/">
              <img
                src={Logo}
                className="logo qa-logo"
                alt="stickerstars logo"
                width="110"
              />
            </a>
          </div>
          <div className="my-3">
            <Form.Control
              as="select"
              value={categoryFilter}
              onChange={onCategoryChange}
              className="qa-blueprint-category-dropdown"
              name="blueprint_category"
            >
              {categories.map(category => (
                <option
                  key={category}
                  value={category}
                  className="qa-blueprint-category-option"
                >
                  {t(category)}
                </option>
              ))}
            </Form.Control>
          </div>
          {blueprints.map(blueprint => (
            <div
              key={blueprint.id}
              className={`qa-blueprint-preview rounded text-center position-relative py-4 ${
                currentBlueprintId === blueprint.id
                  ? `bg-light font-weight-bold qa-active-${blueprint.id}`
                  : ''
              }`}
              onClick={() => onBlueprintChange(blueprint.id)}
              style={{ cursor: 'pointer' }}
            >
              <h6 className="mb-4">{blueprint.title}</h6>
              {currentBlueprintId === blueprint.id && isMobile && (
                <div className="d-flex justify-content-around">
                  <AlbumPreviewNavigator
                    workspace={workspace}
                    style={{ width: '100%', height: '180px' }}
                    flatButtons
                  />
                </div>
              )}
              <BlueprintImage
                hidden={isMobile && currentBlueprintId === blueprint.id}
                url={blueprint.cover_preview_url}
              />
            </div>
          ))}
        </>
      )}
    </div>
  );
}

BlueprintsPanel.defaultProps = {
  blueprints: [],
  currentBlueprintId: null,
};

BlueprintsPanel.propTypes = {
  blueprints: arrayOf(BlueprintPreviewShape),
  currentBlueprintId: string,
  onBlueprintChange: func.isRequired,
  categories: arrayOf(string).isRequired,
  categoryFilter: string.isRequired,
  onCategoryChange: func.isRequired,
  isLoading: bool.isRequired,
  workspace: WorkspaceShape.isRequired,
};

export default BlueprintsPanel;
