import React from 'react';
import { arrayOf, bool, func } from 'prop-types';
import Button from 'react-bootstrap/Button';

import { filterValidItems } from '../../generic/FormValidationErrors';
import { StickerShape } from '../../shapes';
import stickerFormRules from '../SectionPanel/StickerForm/stickerFormRules';
import useLocale from '../../../hooks/localization/useLocale';

function ModalFooter({
  disabled,
  onSubmit,
  onClose,
  onDelete,
  requireAllValid,
  stickers,
}) {
  const { t } = useLocale();
  const validStickers = filterValidItems(stickerFormRules, stickers);

  const allowSubmit = requireAllValid
    ? validStickers.length === stickers.length
    : validStickers.length > 0;

  return (
    <div className="sticker-uploads-modal-footer">
      {onDelete ? (
        <Button
          className="qa-delete"
          variant="outline-danger"
          disabled={disabled}
          onClick={onDelete}
        >
          {t('delete')}
        </Button>
      ) : (
        <Button variant="link" className="qa-cancel" onClick={onClose}>
          {t('cancel')}
        </Button>
      )}
      {allowSubmit && (
        <Button
          disabled={disabled}
          variant="primary"
          onClick={onSubmit}
          className="qa-submit"
        >
          {t('editor.stickerUploadsModal.submit', {
            count: validStickers.length,
          })}
        </Button>
      )}
    </div>
  );
}

ModalFooter.defaultProps = {
  onDelete: null,
};

ModalFooter.propTypes = {
  disabled: bool.isRequired,
  onSubmit: func.isRequired,
  onClose: func.isRequired,
  onDelete: func,
  requireAllValid: bool.isRequired,
  stickers: arrayOf(StickerShape).isRequired,
};

export default ModalFooter;
