import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { string, number } from 'prop-types';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import Icon from '../../../Icon';
import {
  deleteElements,
  moveSpread,
  duplicateNode,
} from '../../../../actions/workspace';
import { historyAnchor } from '../../../../modules/history';
import { getSpreadsCount } from '../../../../selectors/legacy';
import { NodeShape } from '../../../shapes';
import useAnalytics from '../../../../containers/app/useAnalytics';
import { useConfirmation } from '../../../ui/ConfirmModal/ConfirmationService';
import useLocale from '../../../../hooks/localization/useLocale';

function SpreadMeta({ spreadId, spreadIndex, sectionNode }) {
  const { t } = useLocale();
  const dispatch = useDispatch();
  const spreadsCount = useSelector(getSpreadsCount);
  const analytics = useAnalytics();
  const confirm = useConfirmation();

  const {
    props: { name: sectionName, static: staticSection },
    children,
  } = sectionNode;
  const indexInSection = children.indexOf(spreadId);

  const isFirstSpread = indexInSection === 0;
  const isLastSpread = indexInSection === children.length - 1;

  const options = useMemo(
    () =>
      staticSection
        ? []
        : [
            {
              id: 'sendUp',
              icon: 'chevron_up',
              action: () => {
                dispatch(moveSpread(spreadId, -1));
                dispatch(historyAnchor());

                analytics.track('Spread Updated', {
                  position: -1,
                });
              },
              prevent: isFirstSpread,
              label: t('editor.spreadMeta.moveUp'),
            },
            {
              id: 'sendDown',
              icon: 'chevron_down',
              action: () => {
                dispatch(moveSpread(spreadId, +1));
                dispatch(historyAnchor());

                analytics.track('Spread Updated', {
                  position: 1,
                });
              },
              prevent: isLastSpread,
              label: t('editor.spreadMeta.moveDown'),
            },
            {
              id: 'duplicate',
              icon: 'duplicate',
              action: () => {
                dispatch(duplicateNode(spreadId));
                dispatch(historyAnchor());

                analytics.track('Spread Created', {
                  duplicate: true,
                });
              },
              label: t('editor.spreadMeta.duplicate'),
            },
            {
              id: 'trash',
              icon: 'trash',
              action: () => {
                confirm({
                  body: t('editor.spreadMeta.deleteConfirmation'),
                })
                  .then(() => {
                    dispatch(deleteElements([spreadId]));
                    dispatch(historyAnchor());
                    analytics.track('Spread Deleted');
                  })
                  .catch(() => {});
              },
              prevent: spreadsCount === 1 || children.length === 1,
              label: t('editor.spreadMeta.delete'),
            },
          ],
    [
      staticSection,
      isFirstSpread,
      isLastSpread,
      spreadsCount,
      children.length,
      dispatch,
      spreadId,
      analytics,
      confirm,
      t,
    ]
  );

  const allowed = ({ prevent }) => !prevent;

  const isFrontCover = spreadIndex === 0;
  const isBackCover = spreadIndex === spreadsCount - 1;
  const isCover = isFrontCover || isBackCover;

  const pageDescription = isCover
    ? sectionName
    : t('editor.spreadMeta.pageRange', {
        start: spreadIndex * 2,
        end: spreadIndex * 2 + 1,
      });

  return (
    <div className="spread-meta position-fixed w-100">
      <div>{pageDescription}</div>
      {!isCover && <div>{sectionName}</div>}
      <div>
        {options.filter(allowed).map(option => (
          <OverlayTrigger
            key={option.id}
            overlay={<Tooltip>{option.label}</Tooltip>}
          >
            <Button
              variant="transparent"
              className={`p-0 px-1 text-muted qa-spread-action-${option.id}`}
              onClick={option.action}
            >
              <Icon key={option.id} className="btn px-2" name={option.icon} />
            </Button>
          </OverlayTrigger>
        ))}
      </div>
    </div>
  );
}

SpreadMeta.propTypes = {
  spreadId: string.isRequired,
  spreadIndex: number.isRequired,
  sectionNode: NodeShape.isRequired,
};

export default SpreadMeta;
