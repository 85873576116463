import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import flatten from 'lodash/flatten';
import Alert from 'react-bootstrap/Alert';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { getCurrentBlueprintData } from '../../../selectors/blueprints';
import { resolutions } from '../../../constants';
import { ImageContext } from '../../ImageContext';
import SpreadPreview from './SpreadPreview';
import { historyAnchor } from '../../../modules/history';
import { applyBlueprintSpreadToTargetSpread } from '../../../actions/workspace';
import { useConfirmation } from '../ConfirmModal/ConfirmationService';
import { getTargetSpreadEmpty } from '../../../selectors/workspace';
import useAnalytics from '../../../containers/app/useAnalytics';
import { setMobileFooterOpen } from '../../../modules/controls';
import useScreenSize from '../../../hooks/useScreenSize';
import useTutorial from '../../../hooks/useTutorial';
import useLocale from '../../../hooks/localization/useLocale';

const IMAGE_CONTEXT = {
  resolution: resolutions.small,
  showStickers: false,
  cropPreview: true,
  showGuides: false,
  rendering: false,
  stickerRendering: false,
};

function LayoutsPanel() {
  const { t } = useLocale();
  const {
    params: { albumId },
  } = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const targetSpreadEmpty = useSelector(getTargetSpreadEmpty);
  const confirm = useConfirmation();
  const analytics = useAnalytics();
  const { isMobile } = useScreenSize();
  const { linkTo } = useTutorial();

  const [showAlert, setShowAlert] = useState(true);

  const { workspace } = useSelector(getCurrentBlueprintData) || {};
  if (!workspace) {
    return null;
  }

  const { nodes } = workspace;
  const sectionIds = nodes.root.children;
  const spreadIds = flatten(
    sectionIds.map(sectionId => nodes[sectionId].children)
  );

  const handleApply = blueprintSpreadId => {
    dispatch(applyBlueprintSpreadToTargetSpread(blueprintSpreadId));
    dispatch(historyAnchor());
    if (isMobile) {
      history.push(`/album/${albumId}`);
      dispatch(setMobileFooterOpen(false));
    }
  };

  function applySpread(blueprintSpreadId) {
    if (targetSpreadEmpty) {
      handleApply(blueprintSpreadId);

      analytics.track('Blueprint Spread Applied', {
        id: blueprintSpreadId,
        overwrite: false,
        drop: false,
      });
      return;
    }

    /**
     * We open a confirm modal here whenever the spread that is to be
     * replaced by a layout has children, any changes are applied only
     * after user confirm.
     */
    confirm({
      body: t('editor.sidebar.layouts.placeWarning'),
    })
      .then(() => {
        handleApply(blueprintSpreadId);

        analytics.track('Blueprint Spread Applied', {
          id: blueprintSpreadId,
          overwrite: true,
          drop: false,
        });
      })
      .catch(() => {});
  }

  return (
    <>
      <div className="scrollable py-3">
        {showAlert && (
          <Alert
            className="border qa-layouts-welcome-alert"
            variant="light"
            dismissible
            onClose={() => setShowAlert(false)}
          >
            {t('editor.sidebar.layouts.hint')}{' '}
            <a
              href={linkTo('layouts')}
              target="_BLANK"
              rel="noopener noreferrer"
            >
              {t('learnMore')}
            </a>
          </Alert>
        )}
        <ImageContext.Provider value={IMAGE_CONTEXT}>
          {spreadIds.map((spreadId, spreadIndex) => (
            <div key={spreadId} className="p-2">
              <SpreadPreview
                spreadId={spreadId}
                spreadIndex={spreadIndex}
                workspace={workspace}
                spreadsCount={spreadIds.length}
                sectionNode={nodes[nodes[spreadId].parent]}
                onClick={() => applySpread(spreadId)}
              />
            </div>
          ))}
        </ImageContext.Provider>
      </div>
    </>
  );
}

export default LayoutsPanel;
