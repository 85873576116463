import React, { useLayoutEffect, useRef, useState } from 'react';
import { func, number, string, bool } from 'prop-types';
import Form from 'react-bootstrap/Form';
import { useClickAway } from 'react-use';
import { useDispatch } from 'react-redux';
import partition from 'lodash/partition';
import pick from 'lodash/pick';

import Icon from '../../../Icon';
import LocalStickerUploadsButton from './LocalStickerUploadsButton';
import ButtonWithTooltip from '../../../menu/ButtonWithTooltip';
import { maxImageUploads, validStickerKeys } from '../../../../constants';
import LocalStickerUploadsModal from './LocalStickerUploadsModal';
import { generateId } from '../../../../util';
import { createSticker } from '../../../../actions/stickers';
import useLoading, { commonTypes } from '../../../../hooks/useLoading';
import useImageUpload, { imageModels } from '../../../../hooks/useImageUpload';
import useNotifications from '../../../../hooks/useNotifications';
import useAnalytics from '../../../../containers/app/useAnalytics';
import { useConfirmation } from '../../ConfirmModal/ConfirmationService';
import useLocale from '../../../../hooks/localization/useLocale';

function transformLocalStickerUpload(file, index, t) {
  return {
    id: generateId(),
    name:
      (file.name && file.name.split('.')[0]) ||
      t('editor.sidebar.stickers.defaultStickerName'),
    position: '',
    number: index + 1,
    doubleSticker: false,
    sectionId: '',
    showImageLoadingSpinner: true,
  };
}

function SectionSidebarMenu({
  filter,
  setFilter,
  setOpen,
  itemsCount,
  hasSelection,
  onDestroy,
}) {
  const [searchOpen, setSearchOpen] = useState(false);
  const { startLoading, stopLoading } = useLoading(commonTypes.uploadingFiles);
  const { createClientImage } = useImageUpload();
  const { createError, createWarning } = useNotifications();
  const analytics = useAnalytics();
  const confirm = useConfirmation();
  const { t } = useLocale();

  function toggleSearchOpen() {
    const nextSearchOpen = !searchOpen;
    setSearchOpen(nextSearchOpen);

    // Clear filter when closing search
    if (!nextSearchOpen) {
      setFilter('');
    }
  }

  const searchInputRef = useRef();

  useClickAway(searchInputRef, searchOpen ? toggleSearchOpen : null);

  useLayoutEffect(() => {
    if (searchOpen) {
      searchInputRef.current.focus();
    }
  }, [searchOpen]);

  const handleSearchChange = event => setFilter(event.target.value);

  const [modalStickers, setModalStickers] = useState(null);

  const dispatch = useDispatch();

  async function handleRequestUpload(event) {
    const files = Array.from(event.target.files);
    if (files.length > maxImageUploads) {
      createWarning(
        t('editor.imageUpload.maxImagesError', { max: maxImageUploads })
      );
      return;
    }

    try {
      startLoading();
      const allImages = await Promise.all(
        files.map(file => createClientImage(file, imageModels.sticker))
      );
      stopLoading();

      const imageHasErrors = ({ errors }) =>
        errors ? errors.length > 0 : false;
      const [imagesWithErrors, images] = partition(allImages, imageHasErrors);
      if (imagesWithErrors.length) {
        const allErrors = imagesWithErrors.flatMap(({ errors }) => errors);
        dispatch(createWarning(allErrors.join(', ')));
      }

      if (images.length === 0) {
        return;
      }

      // Show the modal
      setModalStickers(
        images.map(
          ({ clientImage, executeUpload, cancelUpload, file }, index) => ({
            ...transformLocalStickerUpload(file, index, t),
            image: clientImage.id,
            clientImage,
            executeUpload,
            cancelUpload,
          })
        )
      );
    } catch (err) {
      createError(t('genericError'));
    } finally {
      stopLoading();
    }
  }

  function handleClose(stickers) {
    // Hide the modal
    setModalStickers(null);

    // Delete temporary client-images when they are present
    stickers.forEach(sticker => sticker.cancelUpload());
  }

  async function handleSubmit(stickers) {
    // Hide the modal
    setModalStickers(null);

    // Create stickers in store and track analytics
    stickers.forEach(sticker => {
      dispatch(createSticker(pick(sticker, validStickerKeys)));
      analytics.track('Sticker Created', {
        id: sticker.id,
        fromFile: true,
        drop: false,
      });
    });

    // Actual upload to server
    startLoading();
    const executeUploads = stickers.map(sticker => sticker.executeUpload());
    await Promise.all(executeUploads);
    stopLoading();
  }

  const handleDestroy = () => {
    confirm({
      body: t('editor.sidebar.stickers.stickerDeleteWarning'),
    })
      .then(onDestroy)
      .catch(() => {});
  };

  return (
    <div className="sidebar-menu py-3 qa-sidebar-section-panel">
      {searchOpen ? (
        <Form.Control
          name="sticker_search"
          className="w-100 qa-sticker-search"
          placeholder={t('editor.sidebar.stickers.search')}
          onChange={handleSearchChange}
          ref={searchInputRef}
          value={filter}
          type="text"
        />
      ) : (
        <>
          {hasSelection ? (
            <button
              type="button"
              className="btn btn-danger w-30 destroy-button qa-destroy-item"
              onClick={handleDestroy}
            >
              <Icon name="trash" />
            </button>
          ) : (
            <>
              <LocalStickerUploadsButton onChange={handleRequestUpload}>
                {t('upload')}
              </LocalStickerUploadsButton>
              {modalStickers && (
                <LocalStickerUploadsModal
                  initialStickers={modalStickers}
                  onClose={handleClose}
                  onSubmit={handleSubmit}
                >
                  {t('upload')}
                </LocalStickerUploadsModal>
              )}
            </>
          )}
          <div className="items-count grow">
            <span className="qa-sticker-count">{`${itemsCount} ${t(
              'stickersPlural'
            )}`}</span>
          </div>
          <ButtonWithTooltip
            className="sidebar-menu-button mx-1 qa-open-all-sections"
            icon="plus_square"
            tooltip={t('editor.sidebar.stickers.expand')}
            onClick={() => setOpen(true)}
          />
          <ButtonWithTooltip
            className="sidebar-menu-button mx-1 qa-close-all-sections"
            icon="minus_square"
            tooltip={t('editor.sidebar.stickers.collapse')}
            onClick={() => setOpen(false)}
          />
          <ButtonWithTooltip
            className="sidebar-menu-button mx-1 qa-toggle-search"
            icon="search"
            tooltip={t('editor.sidebar.stickers.search')}
            onClick={toggleSearchOpen}
          />
        </>
      )}
    </div>
  );
}

SectionSidebarMenu.propTypes = {
  filter: string.isRequired,
  setFilter: func.isRequired,
  itemsCount: number.isRequired,
  hasSelection: bool.isRequired,
  onDestroy: func.isRequired,
  setOpen: func.isRequired,
};

export default SectionSidebarMenu;
