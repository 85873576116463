import React, { useRef } from 'react';
import { arrayOf } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Route, Switch, useRouteMatch } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import { Flipper, Flipped } from 'react-flip-toolkit';

import { getSidebarMinimized } from '../../../../selectors/controls';
import { updateControls } from '../../../../modules/controls';
import Sidebar from '../index';
import TabContent, { TabPane } from '../TabContent';
import TabIcon from './TabIcon';
import Icon from '../../../Icon';
import AlbumTitle from '../AlbumTitle';
import editorTabs from './editorTabs';
import { TabShape } from '../../../shapes';
import useScopedSidebarTabs from '../useScopedSidebarTabs';
import RedirectWithSearchParams from '../../../../containers/app/withSearchParams';
import { selectIsAlbumEditable } from '../../../../selectors/albums';
import FadeInWhenAlbumLoaded from '../../../generic/FadeInWhenAlbumLoaded';
import Logo from '../../../../assets/icons/logo.svg';
import useLocale from '../../../../hooks/localization/useLocale';

export function SidebarMinimizeButton() {
  const dispatch = useDispatch();

  function handleClick() {
    dispatch(updateControls({ sidebarMinimized: true }));
  }

  return (
    <Button
      variant="light"
      className="sidebar-minimize bg-white"
      size="sm"
      onClick={handleClick}
    >
      <Icon name="chevron_left" />
    </Button>
  );
}

function TabNav({ tabs }) {
  const {
    params: { key: activeKey },
  } = useRouteMatch();
  const sidebarMinimized = useSelector(getSidebarMinimized);
  const dispatch = useDispatch();
  const { t } = useLocale();

  const isActiveKey = key => activeKey === key && !sidebarMinimized;
  const openSidebar = () =>
    dispatch(updateControls({ sidebarMinimized: false }));

  return (
    <Flipper flipKey={activeKey}>
      {tabs.map(({ image, key, renderTabIconMeta, symbol }) => (
        <div className="m-1" key={key}>
          <div className="position-relative">
            <Nav.Item key={key} onClick={openSidebar}>
              <Link
                to={key}
                className={`nav-link p-1 qa-tab-${key} ${isActiveKey(key) &&
                  'active'}`}
              >
                <TabIcon
                  title={t(`editor.sidebar.tabs.${key}`)}
                  image={image}
                  type={key}
                  symbol={symbol}
                  renderTabIconMeta={renderTabIconMeta}
                />
              </Link>
            </Nav.Item>
            {key === activeKey && (
              <Flipped flipId="tab-marker">
                <div className="tab-marker rounded w-100 h-100 position-absolute" />
              </Flipped>
            )}
          </div>
        </div>
      ))}
    </Flipper>
  );
}

TabNav.propTypes = {
  tabs: arrayOf(TabShape).isRequired,
};

export function MainNavigation({ tabs }) {
  return (
    <Nav
      className="sidebar-nav flex-column flex-nowrap justify-content-between align-items-center pt-3 px-1 bg-light"
      style={{ zIndex: 1 }}
    >
      <Link to="/">
        <img
          src={Logo}
          className="logo qa-logo"
          alt="stickerstars logo"
          width="70"
        />
      </Link>
      <FadeInWhenAlbumLoaded>
        <div className="scrollable">
          <TabNav tabs={tabs} />
        </div>
      </FadeInWhenAlbumLoaded>
      <div />
    </Nav>
  );
}

MainNavigation.propTypes = {
  tabs: arrayOf(TabShape).isRequired,
};

function EditorSidebar() {
  const sidebarRef = useRef();
  const isAlbumEditable = useSelector(selectIsAlbumEditable);
  const { tabs, activeTab } = useScopedSidebarTabs(editorTabs);
  const sidebarMinimized = useSelector(getSidebarMinimized);
  const { path } = useRouteMatch();

  if (!isAlbumEditable) {
    return null;
  }

  return (
    <Sidebar
      minimized={sidebarMinimized || activeTab?.minimized}
      sidebarRef={sidebarRef}
    >
      <MainNavigation tabs={tabs} />
      <SidebarMinimizeButton />
      <TabContent>
        <Switch>
          {tabs.map(tab => (
            <Route strict key={tab.key} path={`${path}/:key(${tab.key})`}>
              {!(tab.albumTitle === false) && <AlbumTitle />}
              <TabPane component={tab.component} sidebarRef={sidebarRef} />
            </Route>
          ))}
          <RedirectWithSearchParams
            from={`${path}/:key?`}
            to={`${path}/onboarding`}
          />
        </Switch>
      </TabContent>
    </Sidebar>
  );
}

export default EditorSidebar;
