import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useFileUpload from './useFileUpload';
import useApi from './useApi';
import { generateId } from '../util/index';
import { createImage, updateImage } from '../modules/images';
import { deleteImage } from '../actions/images';
import { updateStickersByImageId } from '../actions/stickers';
import { updateElementsByImageId } from '../actions/workspace';
import { getDataUrl, computeImageDimensions } from '../util/images';
import { addToQueue, removeFromQueue } from '../modules/loading';
import { createWarning } from '../actions/notifications';
import { detectFaceFromUrl } from '../util/faceapi';
import { selectCurrentAlbum } from '../selectors/albums';
import { getImage } from '../selectors/images';
import useLocale from './localization/useLocale';

export const imageModels = {
  sticker: 'sticker',
  image: 'image',
};

const maxPixelsPerDimension = 4000;
export const defaultValidators = {
  size: [0.0001, 8],
  type: ['image/jpeg', 'image/png', 'image/svg+xml'],
  totalPixels: maxPixelsPerDimension * maxPixelsPerDimension,
};

const executeScheduleUpload = (id, file, scheduleUpload) => (_, getState) => {
  const clientImage = getImage(getState(), id);
  return scheduleUpload(file, clientImage);
};

function useImageUpload({ validators = defaultValidators } = {}) {
  const { t } = useLocale();
  const { upload } = useFileUpload();
  const currentAlbum = useSelector(selectCurrentAlbum);
  const dispatch = useDispatch();
  const api = useApi();

  const endpoint = `/albums/${currentAlbum}/images`;

  const replaceImage = useCallback(
    ({ clientId, serverImage, model }) => {
      const { id: serverId } = serverImage;

      dispatch(updateImage(clientId, serverImage));

      if (model === imageModels.sticker) {
        dispatch(updateStickersByImageId(clientId, serverId));
      }

      if (model === imageModels.image) {
        dispatch(updateElementsByImageId(clientId, serverId));
      }
    },
    [dispatch]
  );

  const scheduleUpload = useCallback(
    async (file, clientImage) => {
      const { id: clientId, details, model } = clientImage;

      try {
        const { id: blobId } = await upload(file);

        const {
          data: { image: serverImage },
        } = await api.post(endpoint, {
          model,
          blob_id: blobId,
          details,
        });

        replaceImage({
          clientId,
          serverImage,
          model,
        });
      } catch (err) {
        dispatch(
          createWarning(
            t('editor.imageUpload.uploadError', { fileName: file.name })
          )
        );
        dispatch(deleteImage(clientId));
      }
    },
    [api, endpoint, replaceImage, upload, dispatch, t]
  );

  const validateFile = useCallback(
    file => {
      const errors = [];

      const sizeMb = file.size / 1000 / 1000;
      const [min, max] = validators.size;

      if (sizeMb < min || sizeMb > max) {
        errors.push(t('editor.imageUpload.sizeError', { min, max }));
      }

      if (!validators.type.includes(file.type)) {
        errors.push(
          t('editor.imageUpload.typeError', {
            types: validators.type.join(', '),
          })
        );
      }

      return errors;
    },
    [validators, t]
  );

  const validatePixels = useCallback(
    ({ width, height }) => {
      const errors = [];

      if (width * height > validators.totalPixels) {
        errors.push(
          t('editor.imageUpload.dimensionsError', {
            maxDimension: maxPixelsPerDimension,
          })
        );
      }

      return errors;
    },
    [validators, t]
  );

  const createClientImage = useCallback(
    async (file, model) => {
      const fileErrors = validateFile(file);
      if (fileErrors.length > 0) {
        return { errors: fileErrors };
      }

      const dataUrl = await getDataUrl(file);
      const { width, height } = await computeImageDimensions(dataUrl);

      const pixelError = validatePixels({ width, height });
      if (pixelError.length > 0) {
        return { errors: pixelError };
      }

      dispatch(addToQueue(file.name, false));

      const id = generateId();
      const clientImage = {
        id,
        model,
        blob: {
          filename: file.name,
          full: dataUrl,
          large: dataUrl,
          medium: dataUrl,
          original: dataUrl,
          small: dataUrl,
        },
        details: {
          width,
          height,
        },
        meta: {},
        tags: [],
      };

      dispatch(createImage(clientImage));
      dispatch(removeFromQueue(file.name));

      const executeUpload = () =>
        dispatch(executeScheduleUpload(id, file, scheduleUpload));

      const cancelUpload = () => dispatch(deleteImage(id));

      const detectFace = async () => {
        const face = await detectFaceFromUrl(clientImage.blob.full);
        const nextDetails = { ...clientImage.details, face };
        const imageWithFace = { ...clientImage, details: nextDetails };
        dispatch(updateImage(clientImage.id, imageWithFace));
        return imageWithFace;
      };

      return { clientImage, executeUpload, cancelUpload, detectFace, file };
    },
    [dispatch, validateFile, validatePixels, scheduleUpload]
  );

  async function handler(file, model) {
    const {
      errors,
      clientImage,
      executeUpload,
      detectFace,
    } = await createClientImage(file, model);
    if (errors) {
      dispatch(createWarning(errors.join(', ')));
      return null;
    }

    const clientImageWithFace =
      model === imageModels.sticker && (await detectFace());

    executeUpload();
    return clientImageWithFace || clientImage;
  }

  const createStickerImage = file => handler(file, imageModels.sticker);

  const createWorkspaceImage = file => handler(file, imageModels.image);

  return {
    createWorkspaceImage,
    createStickerImage,
    createClientImage,
  };
}

export default useImageUpload;
