import de from './de';
import en from './en';
import fr from './fr';
import it from './it';

const translations = {
  de,
  en,
  fr,
  it,
};

export default translations;
