import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { bool, func } from 'prop-types';

import useAnalytics from './useAnalytics';
import useLocale from '../../hooks/localization/useLocale';

function DesktopOnlyWarning({ show, onClose }) {
  const { t } = useLocale();
  const [linkSent, setLinkSent] = useState(false);
  const analytics = useAnalytics();

  function handleClick() {
    const { href } = window.location;

    analytics.track('Desktop Link Requested', {
      href,
    });
    setLinkSent(true);
  }

  return (
    <Modal
      className="desktop-modal"
      centered
      show={show}
      keyboard={false}
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title>{t('editor.meta.mobileModalHeadline')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{t('editor.meta.mobileModalContent')}</p>
        {!linkSent ? (
          <Button
            className="qa-request-link"
            variant="primary"
            block
            onClick={handleClick}
          >
            {t('editor.meta.mobileModalCta')}
          </Button>
        ) : (
          <Alert className="border">
            {t('editor.meta.mobileModalConfirmation')}
          </Alert>
        )}
        <Button
          onClick={onClose}
          variant="link"
          block
          className="mt-2 qa-close-modal"
        >
          {t('back')}
        </Button>
      </Modal.Body>
    </Modal>
  );
}

DesktopOnlyWarning.propTypes = {
  onClose: func.isRequired,
  show: bool.isRequired,
};

export default DesktopOnlyWarning;
