import React, { useLayoutEffect, useRef, useState } from 'react';
import { func, number, string, bool } from 'prop-types';
import { useDispatch } from 'react-redux';
import { useClickAway } from 'react-use';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';

import { maxImageUploads } from '../../../constants';
import useLoading, { commonTypes } from '../../../hooks/useLoading';
import ButtonWithTooltip from '../../menu/ButtonWithTooltip';
import Icon from '../../Icon';
import FileInputButton from '../../generic/FileInputButton';
import useImageUpload from '../../../hooks/useImageUpload';
import { createWarning } from '../../../actions/notifications';
import useAnalytics from '../../../containers/app/useAnalytics';
import { useConfirmation } from '../ConfirmModal/ConfirmationService';
import useTutorial from '../../../hooks/useTutorial';
import useLocale from '../../../hooks/localization/useLocale';

function ImagesSidebarMenu({
  filter,
  setFilter,
  itemsCount,
  hasSelection,
  onDestroy,
}) {
  const { t } = useLocale();
  const { isLoading } = useLoading(commonTypes.uploadingFiles);
  const { createWorkspaceImage } = useImageUpload();
  const dispatch = useDispatch();
  const analytics = useAnalytics();
  const confirm = useConfirmation();
  const { linkTo } = useTutorial();

  const handleImageUpload = async event => {
    const { files } = event.target;

    if (files.length > maxImageUploads) {
      dispatch(
        createWarning(
          t('editor.sidebar.images.maxImagesError', {
            maxImages: maxImageUploads,
          })
        )
      );
      return;
    }

    for (const file of Array.from(files)) {
      // eslint-disable-next-line no-await-in-loop
      await createWorkspaceImage(file);
    }

    analytics.track('Image Uploaded', {
      button: true,
      sidebar: true,
    });
  };

  const [searchOpen, setSearchOpen] = useState(false);

  function toggleSearchOpen() {
    const nextSearchOpen = !searchOpen;
    setSearchOpen(nextSearchOpen);

    // Clear filter when closing search
    if (!nextSearchOpen) {
      setFilter('');
    }
  }

  const searchInputRef = useRef();

  useClickAway(searchInputRef, searchOpen ? toggleSearchOpen : null);

  useLayoutEffect(() => {
    if (searchOpen) {
      searchInputRef.current.focus();
    }
  }, [searchOpen]);

  const handleSearchChange = event => setFilter(event.target.value);

  const handleDestroy = () => {
    confirm({
      body: t('editor.sidebar.images.deleteWarning'),
    })
      .then(onDestroy)
      .catch(() => {});
  };

  return (
    <div className="sidebar-menu py-3 qa-sidebar-images-panel">
      {searchOpen ? (
        <Form.Control
          name="image_search"
          className="w-100 qa-image-search"
          placeholder={t('search')}
          onChange={handleSearchChange}
          ref={searchInputRef}
          value={filter}
          type="text"
        />
      ) : (
        <>
          {hasSelection ? (
            <button
              type="button"
              className="btn btn-danger w-30 destroy-button qa-destroy-item"
              onClick={handleDestroy}
            >
              <Icon name="trash" />
            </button>
          ) : (
            <FileInputButton
              name="image_upload"
              variant="outline-primary"
              className="qa-images-upload-label m-0 w-30"
              accept="image/jpeg, image/png, image/svg+xml"
              onChange={handleImageUpload}
              multiple
            >
              {isLoading ? (
                <Spinner size="sm" animation="border" />
              ) : (
                t('upload')
              )}
            </FileInputButton>
          )}
          <div className="items-count grow">
            <span className="qa-$images-count">
              {itemsCount} {t('editor.sidebar.images.imagesPlural')}
            </span>
          </div>
          <ButtonWithTooltip
            className="sidebar-menu-button mx-1 qa-toggle-search"
            icon="search"
            tooltip={t('search')}
            onClick={toggleSearchOpen}
          />
          <ButtonWithTooltip
            className="sidebar-menu-button mx-1"
            icon="help"
            tooltip={t('editor.sidebar.images.help')}
            onClick={() => window.open(linkTo('images'), '_blank')}
          />
        </>
      )}
    </div>
  );
}

ImagesSidebarMenu.propTypes = {
  filter: string.isRequired,
  setFilter: func.isRequired,
  itemsCount: number.isRequired,
  hasSelection: bool.isRequired,
  onDestroy: func.isRequired,
};

export default ImagesSidebarMenu;
