import { arrayOf, bool, func, string } from 'prop-types';
import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';

import FormValidationErrors, {
  validate,
} from '../../components/generic/FormValidationErrors';
import { CheckoutFormDataShape } from '../../components/shapes';
import useApi from '../../hooks/useApi';
import useAnalytics from '../app/useAnalytics';
import { commentMaxLength } from './config';
import { BlueprintCategoryShape, CheckoutAlbumShape } from './shapes';
import { snakeify } from '../../util';
import useLocale from '../../hooks/localization/useLocale';

export const requestStates = {
  PENDING: 'PENDING',
  SUBMITTING: 'SUBMITTING',
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS',
};

function getCheckoutFormRules(blueprintCategory, t) {
  return [
    [({ firstName }) => !firstName, t('checkout.firstNameRequired')],
    [({ lastName }) => !lastName, t('checkout.lastNameRequired')],
    [({ email }) => !/\S+@\S+\.\S+/.test(email), t('checkout.emailInvalid')],
    [({ address1 }) => !address1, t('checkout.addressRequired')],
    [({ zipCode }) => !zipCode, t('checkout.zipRequired')],
    [({ city }) => !city, t('checkout.cityRequired')],
    [({ countryCode }) => !countryCode, t('checkout.countryRequired')],
    [
      ({ phone }) => phone && !/^[\d\s]+$/.test(phone),
      t('checkout.phoneNumberFormat'),
    ],
    [
      ({ phone }) => phone && phone.length > 20,
      t('checkout.phoneNumberLength'),
    ],
    [
      ({ comment }) => comment.length > commentMaxLength,
      t('checkout.commentLength'),
    ],
    [
      ({ organization }) => blueprintCategory === 'business' && !organization,
      t('checkout.companyRequired'),
    ],
    [
      ({ organization }) => blueprintCategory === 'sports' && !organization,
      t('checkout.clubRequired'),
    ],
  ];
}

function SubmitControls({
  disabled,
  formData,
  submit,
  goBack,
  blueprintCategory,
}) {
  const { t } = useLocale();
  const [printCheckConsent, setPrintCheckConsent] = useState(false);

  return (
    <>
      <Form.Group className="text-left">
        <Form.Check
          className="qa-print-check-consent"
          checked={printCheckConsent}
          onChange={() => setPrintCheckConsent(prevValue => !prevValue)}
          type="checkbox"
          id="check-consent"
          label={t('checkout.printConsent')}
        />
      </Form.Group>
      <div className="checkout-form-submit">
        <FormValidationErrors
          rules={getCheckoutFormRules(blueprintCategory, t)}
          formData={formData}
        />
        <Button
          variant="primary"
          block
          className="qa-submit-form"
          disabled={disabled || !printCheckConsent}
          onClick={submit}
        >
          {t('checkout.orderWithCost')}
        </Button>
      </div>
      <Button variant="light" block className="mt-3" onClick={goBack}>
        {t('checkout.backToOrderDetails')}
      </Button>
    </>
  );
}

SubmitControls.propTypes = {
  formData: CheckoutFormDataShape.isRequired,
  disabled: bool.isRequired,
  submit: func.isRequired,
  goBack: func.isRequired,
  blueprintCategory: BlueprintCategoryShape.isRequired,
};

function LoadingIndicator() {
  return (
    <div className="m-4">
      <Spinner animation="border" />
    </div>
  );
}

function SuccessMessage() {
  const { t } = useLocale();
  return (
    <Alert variant="success" className="border">
      {t('checkout.orderConfirmation')}
    </Alert>
  );
}
function ErrorMessage({ onClick }) {
  const { t } = useLocale();
  return (
    <Alert variant="danger" className="border">
      {t('checkout.errorMessage')}
      <Button onClick={onClick} variant="link">
        OK
      </Button>
    </Alert>
  );
}

ErrorMessage.propTypes = { onClick: func.isRequired };

export default function CheckoutSubmitControl({
  checkoutStep,
  blueprintCategory,
  setCheckoutStep,
  formData,
  album,
  albumErrors,
}) {
  const { t } = useLocale();
  const [requestState, setRequestState] = useState(requestStates.PENDING);
  const [order, setOrder] = useState({});
  const api = useApi();
  const analytics = useAnalytics();
  const { errors } = validate(
    getCheckoutFormRules(blueprintCategory, t),
    formData
  );

  function handleSubmit() {
    try {
      if (errors.length > 0) {
        throw new Error();
      }
      setRequestState(requestStates.SUBMITTING);
      api
        .post(`/orders`, {
          ...snakeify(formData),
          album_id: album.id,
        })
        .then(({ data: { order: orderFromServer } }) => {
          const {
            album: { release_date: releaseDate },
            amount,
            amount_gross: amountGross,
          } = orderFromServer;
          setOrder(orderFromServer);
          setRequestState(requestStates.SUCCESS);
          analytics.track('Order Created', {
            albumId: album.id,
            releaseDate,
            amount,
            amountGross,
          });
        });
    } catch (err) {
      setRequestState(requestStates.ERROR);
    }
  }

  const createOrderMachine = {
    [requestStates.PENDING]: (
      <SubmitControls
        disabled={errors.length > 0 || albumErrors.length > 0}
        formData={formData}
        blueprintCategory={blueprintCategory}
        submit={handleSubmit}
        goBack={() => setCheckoutStep('product')}
      />
    ),
    [requestStates.SUBMITTING]: <LoadingIndicator />,
    [requestStates.SUCCESS]: <SuccessMessage order={order} />,
    [requestStates.ERROR]: (
      <ErrorMessage onClick={() => setRequestState(requestStates.PENDING)} />
    ),
  };

  return (
    <>
      {checkoutStep === 'preview' && (
        <div className="checkout-form-submit">
          <Button
            variant="primary"
            block
            className="qa-next-step-btn"
            onClick={() => setCheckoutStep('product')}
          >
            {t('checkout.nextStepPackageContents')}
          </Button>
        </div>
      )}
      {checkoutStep === 'product' && (
        <div className="checkout-form-submit">
          <FormValidationErrors
            rules={[
              [
                ({ releaseDate }) => !releaseDate,
                t('checkout.eventDateRequired'),
              ],
            ]}
            formData={formData}
          />
          <Button
            variant="primary"
            block
            className="qa-next-step-btn"
            disabled={albumErrors.length > 0 || !formData.releaseDate}
            onClick={() => setCheckoutStep('contact')}
          >
            {t('checkout.nextStepYourData')}
          </Button>
          <Button
            variant="light"
            block
            className="mt-3"
            onClick={() => setCheckoutStep('preview')}
          >
            {t('checkout.backToAlbumPreview')}
          </Button>
        </div>
      )}
      {checkoutStep === 'contact' && createOrderMachine[requestState]}
    </>
  );
}

CheckoutSubmitControl.propTypes = {
  checkoutStep: string.isRequired,
  blueprintCategory: string.isRequired,
  setCheckoutStep: func.isRequired,
  formData: CheckoutFormDataShape.isRequired,
  album: CheckoutAlbumShape.isRequired,
  albumErrors: arrayOf(string).isRequired,
};
