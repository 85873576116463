import React, { useEffect, useState } from 'react';
import { arrayOf, bool, func } from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { useSelector } from 'react-redux';

import ModalFooter from './ModalFooter';
import StickerUploadsList from './StickerUploadsList';
import StickerForm from '../SectionPanel/StickerForm/StickerForm';
import { getSections } from '../../../selectors/legacy';
import { StickerShape } from '../../shapes';
import useStickerForm from '../SectionPanel/StickerForm/useStickerForm';
import useScreenSize from '../../../hooks/useScreenSize';
import useLocale from '../../../hooks/localization/useLocale';

function StickerUploadsModal({
  stickers,
  updateSticker,
  disabled,
  onSubmit,
  onDelete,
  onClose,
  requireAllValid,
}) {
  const sections = useSelector(getSections);
  const { isMobile } = useScreenSize();
  const { t } = useLocale();

  const {
    setStickerFormData,
    stickerFormData: { stickerId: selectedStickerId },
  } = useStickerForm();

  const setSelectedStickerId = stickerId => setStickerFormData({ stickerId });

  const selectedSticker = stickers.find(({ id }) => id === selectedStickerId);

  function handleFormSubmit(sticker) {
    updateSticker(sticker);
  }

  /** Handling the double-sticker flag is tricky, since it needs to update
   * on changing the currently selected sticker as well as changing
   * the state inside StickerForm */
  const [doubleSticker, setDoubleSticker] = useState(false);

  useEffect(() => {
    setDoubleSticker(selectedSticker?.doubleSticker);
  }, [selectedSticker]);

  function handleFormChange(updatedSticker) {
    setDoubleSticker(updatedSticker.doubleSticker);
  }

  function handleFormClose() {
    setSelectedStickerId('');
  }

  // We will not allow uploads to static sections.
  const sectionOptions = sections
    .filter(section => !section.static)
    .map(section => [section.id, section.name]);

  return (
    <Modal
      className="sticker-uploads-modal qa-sticker-uploads-modal"
      size="xl"
      centered
      onHide={onClose}
      show
    >
      <Container>
        <Row>
          <Col
            md={doubleSticker ? 4 : 3}
            className="bg-light rounded w-100 p-3 d-flex align-items-center qa-edit-sticker-form"
            style={{ top: 0 }}
          >
            {selectedSticker ? (
              <StickerForm
                key={selectedStickerId}
                onSubmit={handleFormSubmit}
                onChange={handleFormChange}
                onClose={handleFormClose}
                initialSticker={selectedSticker}
                viewportScale={3}
                sectionOptions={sectionOptions}
                isExistingSticker={false}
              />
            ) : (
              t('editor.stickerUploadsModal.selectStickerHint')
            )}
          </Col>
          <Col md={doubleSticker ? 8 : 9} className="p-4">
            {!(isMobile && selectedSticker) && (
              <StickerUploadsList
                stickers={stickers}
                selectedStickerId={selectedStickerId}
              />
            )}
            <ModalFooter
              onSubmit={onSubmit}
              disabled={disabled}
              onClose={onClose}
              onDelete={
                selectedSticker ? () => onDelete(selectedSticker) : null
              }
              requireAllValid={requireAllValid}
              stickers={stickers}
            />
          </Col>
        </Row>
      </Container>
    </Modal>
  );
}

StickerUploadsModal.defaultProps = {
  disabled: false,
  onDelete: null,
  onClose: () => {},
  requireAllValid: false,
};

StickerUploadsModal.propTypes = {
  disabled: bool,
  onSubmit: func.isRequired,
  onDelete: func,
  onClose: func,
  requireAllValid: bool,
  stickers: arrayOf(StickerShape).isRequired,
  updateSticker: func.isRequired,
};

export default StickerUploadsModal;
