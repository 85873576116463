import React from 'react';
import { useDispatch } from 'react-redux';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { string } from 'prop-types';

import Icon from '../../../Icon';
import { historyAnchor } from '../../../../modules/history';
import useImageUpload from '../../../../hooks/useImageUpload';
import FileInputButton from '../../../generic/FileInputButton';
import { uploadWorkspaceImage } from '../../../../actions/workspace';
import useAnalytics from '../../../../containers/app/useAnalytics';
import useLocale from '../../../../hooks/localization/useLocale';

function ImageUploadButton({ dropDirection }) {
  const { t } = useLocale();
  const dispatch = useDispatch();
  const { createWorkspaceImage } = useImageUpload();
  const analytics = useAnalytics();

  function handleChange(event) {
    const [file] = Array.from(event.target.files);
    dispatch(uploadWorkspaceImage({ file, createWorkspaceImage }));
    dispatch(historyAnchor());
    analytics.track('Image Uploaded', {
      button: true,
      sidebar: false,
    });
  }

  return (
    <OverlayTrigger
      placement={dropDirection === 'up' ? 'bottom' : 'top'}
      overlay={<Tooltip>{t('editor.toolbar.uploadImage')}</Tooltip>}
    >
      <div>
        <FileInputButton
          name="image-upload-with-overlay"
          accept="image/jpeg, image/png, image/svg+xml"
          className="m-0 qa-image-upload-btn"
          onChange={handleChange}
        >
          <Icon name="image" />
        </FileInputButton>
      </div>
    </OverlayTrigger>
  );
}

ImageUploadButton.propTypes = {
  dropDirection: string.isRequired,
};

export default ImageUploadButton;
