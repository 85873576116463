import { func } from 'prop-types';
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom';
import {
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router';

import useNotifications from '../../../hooks/useNotifications';
import { setAlbumData } from '../../../modules/albums';
import { selectAlbumData } from '../../../selectors/albums';
import useApi from '../../../hooks/useApi';
import Icon from '../../../components/Icon';
import useAnalytics from '../../app/useAnalytics';
import UsersManagement from './UsersManagement';
import useLocale from '../../../hooks/localization/useLocale';
import { languages } from '../../../constants';

function SettingsModal({ onHide }) {
  const { t } = useLocale();
  const dispatch = useDispatch();
  const albumData = useSelector(selectAlbumData);
  const albumPreviewUrlRef = useRef();

  const { url } = useRouteMatch();
  const history = useHistory();
  const { settingsTab } = useParams();

  if (!settingsTab) {
    history.push(`${url}/general`);
  }

  const api = useApi();
  const analytics = useAnalytics();
  const { createError } = useNotifications();

  const {
    id: albumId,
    emailNotificationsEnabled,
    albumPreviewPath,
  } = albumData;

  const albumPreviewUrl = albumPreviewPath
    ? `${window.location.host}${albumPreviewPath}`
    : '';

  function handleCopy() {
    albumPreviewUrlRef.current.select();
    navigator.clipboard.writeText(albumPreviewUrlRef.current.value);
    analytics.track('AlbumPreview URL Copied');
  }

  function handleError() {
    createError(t('settingsModal.saveError'));
  }

  async function handleEmailNotificationsChange(value) {
    const nextAlbumData = { ...albumData, emailNotificationsEnabled: value };
    try {
      dispatch(setAlbumData(nextAlbumData));
      await api.patch(`/albums/${albumId}`, {
        email_notifications_enabled: value,
      });
    } catch (err) {
      handleError();
    }
  }

  async function handleAlbumPreviewChange() {
    try {
      const {
        data: { album_preview_path: path },
      } = await api.patch(`/albums/${albumId}/rotate_album_preview_url_token`);
      dispatch(setAlbumData({ ...albumData, albumPreviewPath: path }));
    } catch (err) {
      handleError();
    }
  }

  async function handleLanguageChange({ target: { value } }) {
    const nextAlbumData = { ...albumData, language: value };
    try {
      dispatch(setAlbumData(nextAlbumData));
      await api.patch(`/albums/${albumId}`, {
        language: value,
      });
    } catch (err) {
      handleError();
    }
  }

  return (
    <Modal
      size="lg"
      show
      onHide={onHide}
      centered
      className="settings-modal qa-settings-modal"
    >
      <Modal.Header className="p-4 qa-settings-modal-header" closeButton>
        <Modal.Title>{t('settingsModal.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column px-4">
        <Tab.Container activeKey={settingsTab}>
          <Nav variant="tabs" className="mb-3">
            <Nav.Item>
              <Nav.Link eventKey="general" as={Link} to="general">
                {t('settingsModal.generalTab')}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey="users"
                as={Link}
                to="users"
                className="qa-settings-tab-users"
              >
                {t('settingsModal.usersTab')}
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Switch>
              <Route path="/album/:albumId/:childKey/settings/users">
                <UsersManagement />
              </Route>
              <Route path="*/settings">
                <Form>
                  <Form.Group controlId="emailNotificationsEnabled">
                    <Form.Check
                      type="checkbox"
                      label={t('settingsModal.emailNotifications')}
                      checked={emailNotificationsEnabled}
                      onChange={() =>
                        handleEmailNotificationsChange(
                          !emailNotificationsEnabled
                        )
                      }
                      className="qa-email-notification"
                    />
                    <small className="text-muted">
                      {t('settingsModal.emailNotificationsHelp')}
                    </small>
                  </Form.Group>
                  <Form.Group controlId="albumPreviewEnabled">
                    <Form.Check
                      type="checkbox"
                      label={t('settingsModal.albumPreview')}
                      checked={albumPreviewUrl}
                      onChange={handleAlbumPreviewChange}
                      className="qa-toggle-album-preview"
                    />
                    <small className="text-muted">
                      {t('settingsModal.albumPreviewHelp')}
                    </small>
                    <InputGroup className="mt-2">
                      <FormControl
                        type="text"
                        className="qa-album-preview-url"
                        value={albumPreviewUrl}
                        ref={albumPreviewUrlRef}
                        readOnly
                        name="album_preview_url"
                      />
                      <InputGroup.Append>
                        <Button variant="dark" onClick={handleCopy}>
                          <Icon name="clipboard" />
                        </Button>
                      </InputGroup.Append>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>{t('settingsModal.language')}</Form.Label>
                    <Form.Control
                      as="select"
                      value={albumData.language}
                      onChange={handleLanguageChange}
                    >
                      {languages.map(lang => (
                        <option key={lang} value={lang}>
                          {t(`languages.${lang}`)}
                        </option>
                      ))}
                    </Form.Control>
                    <small className="text-muted">
                      {t('settingsModal.languageHelp')}
                    </small>
                  </Form.Group>
                </Form>
              </Route>
            </Switch>
          </Tab.Content>
        </Tab.Container>
      </Modal.Body>
    </Modal>
  );
}

SettingsModal.propTypes = {
  onHide: func.isRequired,
};

export default SettingsModal;
